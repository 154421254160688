import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <section id="hero">
                <div class="hero-container">
                    <h1>Welcome to Umdoko Internet<br/>[UI]</h1>
                    <h2>Automating processes through <strong>modern software development</strong></h2>
                    <a href="#about" class="btn-get-started">Your vision. Your solution. Start here</a>
                </div>
            </section>
        );
      }
}
export default Home;