import React from 'react';
import "../styles/CaseStudies.css"
import AwsServices from "../assets/AWS Services use by Amusema.jpg";
import Header from './../components/home/header-case-studies';
import Footer from './../components/home/footer';


export default function UsecaseBwireless() {
    return (
        <>
        <Header />

        <section id="case-studies">
        <p><br/></p>
        <div class="container wow fadeIn">
                <div class="row">
                    <div class="col-lg-9 text-center text-lg-left">
                    <h1 class="h1 cta-text">BWireless Energy Systems</h1>
                    <span class="badge badge-pill badge-info">Energy - Power & Utilities</span>
                    </div>
                    <div class="col-lg-3 cta-btn-container text-center">

                    <a href="mailto:builders@umdoko.com" class="nav-link active">Let's help you build</a>
                    </div>
                </div>
        </div>            
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Challenges</h2>
                    <p>
                    BWireless Energy Systems uses a paper based system to control and manage attendees that attend their in-person solar events. It's difficult for them to track and securely store attendees data. 
                    </p>
                </div>

            </div>
        </div>
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Solution</h2>
                    <p>The Amusema platform empowers BWireless Energy Systems to establish it's event's digital presence, gather attendee registrations, manage ticket transactions, and monitor participant influx seamlessly.</p>
                    <p>
                    The ticketing and registration system is another highlight. Amusema provided a hassle-free solution for managing registrations, sending out e-tickets, and tracking attendance. The customization options allowed me to tailor the registration process to fit the unique requirements of my event, resulting in a professional and streamlined experience for participants.
                    </p>
                    <p>One of the standout features of Amusema platform is its user-friendly interface. Navigating through the platform is a breeze, even for someone who isn't a tech expert. The intuitive design and well-thought-out layout make setting up and managing an event a straightforward and enjoyable process.</p>                    
                </div>
            </div>
        </div>
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">AWS services used</h2>
                    <p>
                    <img src={AwsServices} alt="AWS Services" width="100%" />

                    </p>
                </div>

            </div>
        </div>
        <p><br/></p>
        <div class="jumbotron  bg-light rounded">
            <div class="container">
                <h1 class="display-5">"Commitment to quality"</h1>
                <p class="lead">I'm confident in Umdoko's abilities after our recent interactions. They demonstrated a clear understanding of our goals and can handle complex tasks efficiently. Their technological skills, proactive approach, commitment to quality, and excellent customer service make them an ideal partner for our project.</p>
                <footer class="blockquote-footer">Tebogo Segole, Managing Director at BWireless Energy Systems</footer>
            </div>
        </div>

        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Benefits</h2>
                    <p>
                    Reimagining event management to assist you in reaching desired goals more quickly. Amusema leverage the power of technology to make event organiser's lives easier and simplify their engagement with event attendees.
                    </p>
                    <p>Seamless Efficiency: Amusema streamlines the entire event management process, eliminating the need for multiple tools. From planning to execution, experience unparalleled efficiency as you effortlessly navigate through a user-friendly interface, saving time and resources. </p>
                    <p>Simplicity: Amusema streamlines event planning, so you can focus on creating memorable experiences instead of getting lost in details.</p>
                    <p>Innovation: With cutting-edge tools, Amusema bring fresh ideas and possibilities to your events, making them stand out.</p>
                </div>
            </div>
        </div>
      
        </section>
        <Footer />
        </>
    )
}