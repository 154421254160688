import React, { Component } from 'react';


class Services extends Component {
    render() {
        return (
            <section id="services">
                <div class="container wow fadeIn">
                    <div class="section-header">
                    <h3 class="section-title">Services</h3>
                    <p class="section-description">At UI, we aim to provide you with services that make your business infinitely more powerful and competitive</p>
                    </div>
                    <div class="row">
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-code"></i></a></div>
                        <h4 class="title"><a href="">Web App Development</a></h4>
                        <p class="description">Built for ease of use and responsiveness using the industries best practices, coding languages and tools, web apps will run on Chrome, Firefox, Safari & Internet Explorer browsers.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-mobile"></i></a></div>
                        <h4 class="title"><a href="">Mobile App Development</a></h4>
                        <p class="description">Built using the industries best cross-platform, open source & commercial tools combined with robust programming languages, apps will run natively on iOS, Android & Windows.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-cogs"></i></a></div>
                        <h4 class="title"><a href="">System Integrations</a></h4>
                        <p class="description">Built using proven technologies & orchestration platforms, multiple systems can communicate data & events to each other on a schedule or in real-time.</p>
                        </div>
                    </div>
            
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.2s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-cloud"></i></a></div>
                        <h4 class="title"><a href="">Cloud Computing</a></h4>
                        <p class="description">'X'aaS - Cloud computing is the delivery of computing services—servers, storage, databases, networking, software, analytics and more—over the Internet.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-plus"></i></a></div>
                        <h4 class="title"><a href="">New Development</a></h4>
                        <p class="description">Your Proof of concept to life - where and how to start? We help you to answer these questions & take the next step forward.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                        <div class="box">
                        <div class="icon"><a href=""><i class="fa fa-street-view"></i></a></div>
                        <h4 class="title"><a href="">DevOps</a></h4>
                        <p class="description">We are agile. DevOps is the union of people, process, and products to enable continuous delivery of value to our end users.</p>
                        </div>
                    </div>
                    </div>
            
                </div>
            </section>
        );
      }
}
export default Services;