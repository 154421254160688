import React from 'react';
import Header from './../components/home/header';
import Hero from './../components/home/home';
import Careers from './../components/home/careers';
import Specialists from './../components/home/specialists';
import Amuseafrica from './../components/home/amuseafrica';
import About from './../components/about-us/about';
import Facts from './../components/home/facts';
import Services from './../components/services';
import Techstack from './../components/tech-stack';
import Customers from "./../components/customers";
import ContactUs from './../components/contact-us';
import Footer from './../components/home/footer';;


export default function Home() {
    return (
        <>
        <Header/>
        <Hero />
        <About />
        <Amuseafrica />
        <Facts />
        <Services />
        <Specialists />
        <Customers />
        <Careers />
        <Techstack />
        <ContactUs />
        <Footer />
        </>
    )
}