import React, { Component } from 'react';

class Careers extends Component {
    render() {
        return (
        <div>
            <section id="call-to-action">
            <div class="container wow fadeIn">
                <div class="row">
                    <div class="col-lg-9 text-center text-lg-left">
                    <h3 class="cta-title">We’re always looking for smart people</h3>
                    <p class="cta-text">Join an enthusiastic team and work with us on solutions customers love to use!</p>
                    </div>
                    <div class="col-lg-3 cta-btn-container text-center">

                    <button type="button" class="btn btn-info" data-toggle="modal" data-target="#exampleModalCenter">
                    We’re hiring!
                    </button>
                    </div>
                </div>
        
                </div>
            </section>

            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Open positions</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div class="modal-body">
                    <p>
                    <h2>Fullstack cloud (AWS) engineers</h2>
                    Minimum 3 years exp in coding - a must! <br/>
                    Back-end: C#, Java, Go, Rust<br/>
                    Front-end: React, Angular, React-Native, Flutter<br/>
                    OS: Linux, Windows<br/>
                    Databases: MS SQL, PostgreSQL, MySQL<br/>
                    Application Servers: IIS, Nginx, Apache

                    </p>
                    Please send us your CV to <a href="#">careers [at] umdoko [dot] com</a>
                        </div>
                        <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
      }
}
export default Careers;