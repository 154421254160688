import React from 'react';
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import UsecaseMotionrate from "./pages/UsecaseMotionrate";
import UsecaseBwireless from "./pages/UsecaseBwireless";
import UsecaseLavisaTechnologies from "./pages/UsecaseLavisaTechnologies";



function App() {
    return (

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/case-studies/motionrate" element={<UsecaseMotionrate />} />
            <Route path="/case-studies/bwireless" element={<UsecaseBwireless />} />
            <Route path="/case-studies/lavisatechnologies" element={<UsecaseLavisaTechnologies />} />
            <Route path="*" element={<Page404 />} />
        </Routes>

    )
}
export default App