import React, { Component } from 'react';

class Amuseafrica extends Component {
    render() {
        return (
            <section id="amuseafrica">
            <div class="container wow pulse">
              <div class="jumbotron">
                <h1 class="display-4 text-center">event management, seamlessly.</h1><h2 class="text-center">media & entertainment.</h2>
                <h3 class="text-right">shape the future, be an early adopter &#8594; <a href="https://amusema.com" target="blank">amusema.com</a></h3>
              </div>
            </div>
            <div class="container wow pulse">
              <div class="jumbotron">
                <h1 class="display-4 text-center">live music. art. tech. wine.</h1><h2 class="text-center">media & entertainment.</h2>
                <h3 class="text-right">get in &#8594; <a href="https://amuse.africa" target="blank">amuse.<strong>africa</strong></a></h3>
              </div>
            </div>            
          </section>
        );
      }
}
export default Amuseafrica;