import React, { Component } from 'react';

class About extends Component {
    render() {
        return (
            <section id="about">
                <div class="container">
                    <div class="row about-container">

                        <div class="col-lg-6 content order-lg-1 order-2">
                            <h2 class="title">About Umdoko Internet [UI]</h2>
                            <p>
                                Umdoko Internet is a technology start-up company providing modern software development. We create custom software that inspires people and drives business forward. Our technologies and solutions underpin the key objectives and values of the Fourth Industrial Revolution (4IR).
                                <br /><br />

                                We are the geeks, the nerds who just love technology, data, numbers and stats. Our passion is innovative thinking and bringing to life modern cutting edge solutions for our customers.
                                <br /><br />
                                Evolvable architecture - we use a fail fast and learn fast approach which enables us to adopt quickly to forever changes of our customers’ requirements. <a href="mailto:builders@umdoko.com">Let's help you build.</a>

                            </p>
                        </div>
                        <div class="col-lg-6 background order-lg-2 order-1 wow fadeInRight"></div>
                    </div>
                    <br />
                    <div class="row justify-content-center">
                        <blockquote class="blockquote">
                            <p><i>We help small to medium-sized businesses build software better and faster than their internal teams can alone.</i>
                            </p>
                        </blockquote>

                    </div>
                    <div class="row justify-content-center">
                        <p><img src="img/aws-advanced-partner-badge.png" class="img-fluid" alt="AWS Advanced Partner" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <img src="img/aws-qualified-software-badge.png" class="img-fluid" alt="AWS Qualified Software Product" /></p>
                    </div>
                </div>
            </section>
        );
    }
}
export default About;