import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
            <footer id="footer">
                <div class="container">
                    <div class="copyright">
                        &copy; Copyright <strong>Umdoko Internet Solutions (Pty) Limited</strong>. All Rights Reserved
                    </div>
                </div>
            </footer>
        );
      }
}
export default Footer;