import React, { Component } from 'react';


class HeaderCaseStudies extends Component {
    render() {
        return (
            <header id="header">
                <div class="container">
                    <div id="logo" class="pull-left">
                        <h1><a href="/#hero">[UI]</a></h1>
                       
                    </div>
                    <div class="pull-right text-light">Case studies</div>
                </div>
            </header>
        );
      }
}
export default HeaderCaseStudies;