import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


class Specialists extends Component {
    render() {
        return (
            <section id="facts">
                <div class="container wow fadeIn">
                    <div class="section-header">
                        <h3 class="section-title">Speciality</h3>
                        <p class="section-description">
                        <i>inter-alia</i> our core <sup>fantastic four</sup> focus areas</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-6 text-center">
                            <FontAwesomeIcon icon={['fab', 'jenkins']} size="6x" /><br/>
                            <small>automation<br/>ci/cd</small>
                        </div>
                        <div class="col-lg-3 col-6 text-center">
                            <FontAwesomeIcon icon={['fab', 'salesforce']} size="6x" />
                        </div>
                        <div class="col-lg-3 col-6 text-center">
                            <FontAwesomeIcon icon='glasses' size="6x" /> <br/>
                            <small>augmented reality</small>
                        </div>
                        <div class="col-lg-3 col-6 text-center">
                        <   FontAwesomeIcon icon='cubes' size="6x" /> <br/>
                            <small>blockchain</small>
                        </div>
                    </div>
                </div>
            </section>
        );
      }
}
export default Specialists;