import React from 'react';
import "../styles/CaseStudies.css"
import AwsServices from "../assets/AWS Services use by Amusema.jpg";
import Header from '../components/home/header-case-studies';
import Footer from '../components/home/footer';


export default function UsecaseLavisaTechnologies() {
    return (
        <>
        <Header />

        <section id="case-studies">
        <p><br/></p>

        <div class="container wow fadeIn">
                <div class="row">
                    <div class="col-lg-9 text-center text-lg-left">
                    <h1 class="h1 cta-text">Lavisa Technologies (Pty) Ltd</h1>
                    <span class="badge badge-pill badge-info">Computers & Electronics</span>
                    </div>
                    <div class="col-lg-3 cta-btn-container text-center">

                    <a href="mailto:builders@umdoko.com" class="nav-link active">Let's help you build</a>
                    </div>
                </div>
        </div>            
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Challenges</h2>
                    <p>
                    Lavisa Technologies use Zoom to do their online training events. It's difficult for them to secure these trainings with Zoom as trainees and technicians share links.
                    This can result in a loss of control over who has access to the training material, potentially compromising the confidentiality of the shared content.
                    </p>
                </div>

            </div>
        </div>
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Solution</h2>
                <p>
                Amusema is a all-in-one event management platform that solves this problem by creating virtual live training events — only trainees and technicians who has bought the ticket can access these live training events by authenticating using Amazon Cognito.

                This tie secure links to specific technician user accounts to prevent sharing among unauthorized individuals.
                </p>
                </div>
            </div>
        </div>
        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">AWS services used</h2>
                    <p>
                    <img src={AwsServices} alt="AWS Services" width="100%" />

                    </p>
                </div>

            </div>
        </div>
        <p><br/></p>
        <div class="jumbotron  bg-light rounded">
            <div class="container">
                <h1 class="display-5">"Perfection in event management"</h1>
                <p class="lead"> I highly recommend Amusema platform  to event planners, businesses, and individuals alike — this platform truly delivers perfection in event management.</p>
                <footer class="blockquote-footer">Desmond Lavisa, Director at Lavisa Technologies</footer>
            </div>
        </div>

        <p><br/></p>
        <div class="container">
            <div class="row about-container">
                <div class="col-lg-12 cta-text">
                    <h2 class="title">Benefits</h2>
                    <p>
                    Reimagining event management to assist you in reaching desired goals more quickly. Amusema leverage the power of technology to make event organiser's lives easier and simplify their engagement with event attendees.
                    </p>
                    <p>Seamless Efficiency: Amusema streamlines the entire event management process, eliminating the need for multiple tools. From planning to execution, experience unparalleled efficiency as you effortlessly navigate through a user-friendly interface, saving time and resources. </p>
                    <p>Simplicity: Amusema streamlines event planning, so you can focus on creating memorable experiences instead of getting lost in details.</p>
                    <p>Innovation: With cutting-edge tools, Amusema bring fresh ideas and possibilities to your events, making them stand out.</p>
                </div>
            </div>
        </div>
      
        </section>
        <Footer />
        </>
    )
}