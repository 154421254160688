import React, { Component } from 'react';

class Facts extends Component {
    render() {
        return (
            <section id="facts">
                <div class="container wow fadeIn">
                    <div class="section-header">
                        <h3 class="section-title">Facts</h3>
                        <p class="section-description">We enable the enterprise to harness the power of data and apply a unified cultural strategy</p>
                    </div>
                    <div class="row counters">
            
                    <div class="col-lg-3 col-6 text-center">
                    <span data-toggle="counter-up">8</span>
                    <p>Years developing clean software</p>
                    </div>
            
                    <div class="col-lg-3 col-6 text-center">
                    <span data-toggle="counter-up">893,568</span>
                    <p>Lines of code</p>
                    </div>
            
                        <div class="col-lg-3 col-6 text-center">
                            <span data-toggle="counter-up">108</span>
                            <p>Scalable cloud instances</p>
                    </div>
            
                        <div class="col-lg-3 col-6 text-center">
                            <span data-toggle="counter-up">100</span>
                            <p>Percent satisfied customers</p>
                    </div>
                    </div>
                </div>
            </section>
        );
      }
}
export default Facts;