import React, { Component } from 'react';

class Customers extends Component {
    render() {
        return (
            <section id="customers">
                <hr /><br/>
                <div class="container wow fadeInUp">
                    <div class="section-header">
                        <h3 class="section-title">Customers</h3>
                        <p class="section-description">Trusted by the South Africa’s leading organizations.<br/>
                        Umdoko Internet is a customer centric company and these are the giants in their own industries that entrusted us to develop and configure software for them.
                        </p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <ul id="customers-filters">
                                <li data-filter=".filter-financial, .filter-fintech, .filter-nonprofit, .filter-healthcare, .filter-telecoms, .filter-it" class="customers-filter-active">All Industries</li>
                                <li data-filter=".filter-financial">Financial Services</li>
                                <li data-filter=".filter-fintech">Fintech</li>
                                <li data-filter=".filter-nonprofit">Non-Profit</li>
                                <li data-filter=".filter-healthcare">Healthcare</li>
                                <li data-filter=".filter-telecoms">Telecommunications</li>
                                <li data-filter=".filter-it">Information Technology</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row" id="customers-wrapper">
                        <div class="col-lg-3 col-md-6 customers-item filter-financial">
                            <img src="img/customers/ABSA_Logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Absa Bank</h4>
                                <span>Software Dev, AWS Cloud, DevOps</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 customers-item filter-financial">
                            <img src="img/customers/OM_Logo.jpg" className="img-fluid" alt=""/>
                            <div className="details">
                                <h4>Old Mutual</h4>
                                <span>Software Dev, Azure Cloud, DevOps</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 customers-item filter-fintech">
                            <img src="img/customers/Transaction-Capital-New-Nutun-Logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Transaction Capital</h4>
                                <span>Software Dev, Integration</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 customers-item filter-fintech">
                            <img src="img/customers/bank zero logo.jpg" className="img-fluid"
                                 alt=""/>
                            <div className="details">
                                <h4>Bank Zero</h4>
                                <span>DevOps</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 customers-item filter-nonprofit">
                            <img src="img/customers/ISFAP-LOGO.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>ISFAP</h4>
                                <span>Software Dev, DevOps</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 customers-item filter-healthcare">
                            <img src="img/customers/netcare-plus-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Netcare Plus</h4>
                                <span>Software Dev, DNN</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 customers-item filter-telecoms">
                            <img src="img/customers/Vodacom-Logo.wine.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Vodacom</h4>
                                <span>Software Dev</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 customers-item filter-telecoms">
                            <img src="img/customers/mtn-logo.jpg" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>MTN</h4>
                                <span>Software Dev</span>
                            </div>
                        </div>


                        <div class="col-lg-3 col-md-6 customers-item filter-it">
                            <img src="img/customers/DULogov5.jpg" class="img-fluid" alt="Digital Unbounded" />
                            <div class="details">
                                <h4>Digital Unbounded</h4>
                                <span>Software Dev, DNN</span>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
        );
    }
}
export default Customers;