import React, { Component } from 'react';

class ContactUs extends Component {
    render() {
        return (
            <section id="contact">
                <div className="container wow fadeInUp">
                    <div className="section-header">
                        <h3 className="section-title">Contact</h3>
                        <p className="section-description">On both sides of the Atlantic, we are glad to help you</p>
                    </div>
                </div>
        
                <div>
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.7350059688765!2d28.050444615595072!3d-26.10756761639346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e9573fac7e034cf%3A0x37452d67c0db58bc!2sUmdoko+Internet!5e0!3m2!1sen!2sza!4v1530702626553" 
                        width="100%" 
                        height="450" 
                        frameBorder="0" 
                        allowFullScreen>
                    </iframe>
                    <br/><br/>
                </div>
                <div className="container wow fadeInUp">


                <div className="row justify-content-center">
        

                <div className="col-lg-12 col-md-4">

                    <div className="info">
                        <div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <i className="fa fa-map-marker"></i><p><strong>Head Office</strong><br/>27 Duiker Road<br/>Sunward Park, Boksburg<br/>1459</p>
                                </div>
                                <div class="col-sm-6"><i className="fa fa-map-marker"></i><p><strong>Sandton Office</strong><br/>Atrium on 5th - 9th Floor<br/>5th Street Sandhurst<br/>Sandton City, 2196</p></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-4">

                    <div className="info">

            
                            <div><i className="fa fa-envelope"></i><p><a href="#">info [at] umdoko [dot] com</a></p></div>
                            <div><i className="fa fa-phone"></i><p><a href="tel:+27 10 026 9999">+27 10 026 9999</a></p></div>
                    </div>
                </div>                

    </div>


                    <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-4">
                        <div className="social-links">
                            <a href="https://twitter.com/umdokoapp" className="twitter"><i className="fa fa-twitter"></i></a>&nbsp;&nbsp;&nbsp;
                            <a href="https://www.facebook.com/Umdoko" className="facebook"><i className="fa fa-facebook"></i></a>&nbsp;&nbsp;&nbsp;
                            <a href="https://www.instagram.com/umdokoapp/" className="instagram"><i className="fa fa-instagram"></i></a>&nbsp;&nbsp;&nbsp;
                            <a href="https://www.linkedin.com/company/umdoko/" className="linkedin"><i className="fa fa-linkedin"></i></a>
                        </div>
        
                    </div>
        
                </div>
        
                </div>
          </section>
        );
      }
}
export default ContactUs;