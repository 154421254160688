import React, { Component } from 'react';


class Header extends Component {
    render() {
        return (
            <header id="header">
                <div class="container">
                    <div id="logo" class="pull-left">
                        <h1><a href="/#hero">[UI]</a></h1>
                    </div>
            
                    <nav id="nav-menu-container">
                        <ul class="nav-menu">
                        <li class="menu-active"><a href="/#hero">Home</a></li>
                        <li><a href="/#about">About Us</a></li>
                        <li><a href="/#services">Services</a></li>
                        <li><a href="/#customers">Customers</a></li>
                        <li><a href="/#portfolio">Tech stack</a></li>
                        <li><a href="/#contact">Contact Us</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
      }
}
export default Header;