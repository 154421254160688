import React, { Component } from 'react';

class Techstack extends Component {
    render() {
        return (
            <section id="portfolio">
                <div class="container wow fadeInUp">
                    <div class="section-header">
                        <h3 class="section-title">Tech stack</h3>
                        <p class="section-description">We are standing on the shoulders of giants</p>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <ul id="portfolio-flters">
                                <li data-filter=".filter-colaboration, .filter-frontend, .filter-backend, .filter-dev, .filter-cloud, .filter-platforms" class="filter-active">All</li>
                                <li data-filter=".filter-colaboration">Collaboration</li>
                                <li data-filter=".filter-frontend">Front-end</li>
                                <li data-filter=".filter-backend">Back-end</li>
                                <li data-filter=".filter-cloud">Cloud</li>
                                <li data-filter=".filter-dev">Dev tools</li>
                                <li data-filter=".filter-platforms">Platforms</li>
                            </ul>
                        </div>
                    </div>
            
                    <div class="row" id="portfolio-wrapper">
                        <div class="col-lg-3 col-md-6 portfolio-item filter-colaboration">
                        <img src="img/techstack/slack-logo.gif" class="img-fluid" alt="" />
                        <div class="details">
                            <h4>Slack</h4>
                            <span>Our team never miss a beat</span>
                        </div>
                        </div>

                        <div class="col-lg-3 col-md-6 portfolio-item filter-colaboration">
                        <img src="img/techstack/MS-Teams-Header.jpeg" class="img-fluid" alt="" />
                        <div class="details">
                            <h4>Ms Teams</h4>
                            <span>A chart-based workspace in Office 365</span>
                        </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 portfolio-item filter-frontend">
                        <img src="img/techstack/react.png" class="img-fluid" alt="" />
                        <div class="details">
                            <h4>React / React native</h4>
                            <span>React, Redux, Observable, Typescript</span>
                        </div>
                        </div>
                
                        <div class="col-lg-3 col-md-6 portfolio-item filter-frontend">
                        <img src="img/techstack/angular2.png" class="img-fluid" alt="" />
                        <div class="details">
                            <h4>Angular</h4>
                            <span>Angular 2+</span>
                        </div>
                        </div>

                        <div class="col-lg-3 col-md-6 portfolio-item filter-frontend">
                        <img src="img/techstack/flutter-logo.jpg" class="img-fluid" alt="" />
                        <div class="details">
                            <h4>Flutter</h4>
                            <span>Crosss platform, Dart</span>
                        </div>
                        </div>                        
                        
                        <div class="col-lg-3 col-md-6 portfolio-item filter-frontend">
                            <img src="img/techstack/JavaScript-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                            <h4>Java script</h4>
                            <span>ES6, Pure functions</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-frontend">
                            <img src="img/techstack/css-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Cascading style sheets</h4>
                                <span>Sass preprocessor</span>
                            </div>
                        </div>
                        
                        <div class="col-lg-3 col-md-6 portfolio-item filter-backend">
                            <img src="img/techstack/Csharp.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>.Net</h4>
                                <span>.Net framework, .Net core, .Net</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 portfolio-item filter-backend">
                            <img src="img/techstack/golang-logo.png" className="img-fluid" alt=""/>
                            <div className="details">
                                <h4>Go</h4>
                                <span>simple, reliable, efficient</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 portfolio-item filter-backend">
                            <img src="img/techstack/rust.jpeg" className="img-fluid" alt=""/>
                            <div className="details">
                                <h4>Rust</h4>
                                <span>Performance unmatched, Web Assembly</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-backend">
                            <img src="img/techstack/nodejs-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Node js</h4>
                                <span>NPM, Yarn</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-backend">
                            <img src="img/techstack/JavaLogo.jpg" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Java</h4>
                                <span>Java SE & Java EE</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 portfolio-item filter-cloud">
                            <img src="img/techstack/AWS-logo.png" className="img-fluid" alt=""/>
                            <div className="details">
                                <h4>AWS</h4>
                                <span>Containers, EKS, Serverless</span>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6 portfolio-item filter-cloud">
                            <img src="img/techstack/Azure.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Microsoft Azure</h4>
                                <span>Scalable services, Big data, Cloud functions</span>
                            </div>
                        </div>
                
                        <div class="col-lg-3 col-md-6 portfolio-item filter-cloud">
                            <img src="img/techstack/google-cloud-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Google Cloud Platform</h4>
                                <span>Scalable services, Big data, cloud functions</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 portfolio-item filter-dev">
                            <img src="img/techstack/jetbrains-logo.png" className="img-fluid" alt=""/>
                            <div className="details">
                                <h4>JetBrains</h4>
                                <span>IntelliJ IDEs</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-dev">
                            <img src="img/techstack/Visual-StudioLogo.jpg" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Visual Studio</h4>
                                <span>Visual studio, Visual studio code</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-dev">
                            <img src="img/techstack/Atlassian-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Atlassian</h4>
                                <span>Bitbucket, Jira, Confluence</span>
                            </div>
                        </div>                        
                        <div class="col-lg-3 col-md-6 portfolio-item filter-dev">
                            <img src="img/techstack/vmware.png" class="img-fluid" alt="" />
                            <div class="details">
                            <h4>VMware</h4>
                            <span>Virtual machines</span>
                            </div>
                        </div>
                
                        <div class="col-lg-3 col-md-6 portfolio-item filter-platforms">
                            <img src="img/techstack/WWW-Icon.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Web</h4>
                                <span>Web & mobile</span>
                            </div>
                        </div>
                
                        <div class="col-lg-3 col-md-6 portfolio-item filter-platforms">
                            <img src="img/techstack/iOS-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Apple</h4>
                                <span>iOS 6... iOS 12</span>
                            </div>
                        </div>
                
                        <div class="col-lg-3 col-md-6 portfolio-item filter-platforms">
                            <img src="img/techstack/android-logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Android</h4>
                                <span>Kitkat... Pie</span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 portfolio-item filter-platforms">
                            <img src="img/techstack/WindowsPhone_logo.png" class="img-fluid" alt="" />
                            <div class="details">
                                <h4>Windows</h4>
                                <span>Universal Windows Platform</span>
                            </div>
                        </div>
                    </div>
                </div>
          </section>
        );
      }
}
export default Techstack;